import React from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import 'bootstrap/scss/bootstrap.scss'
import './assets/css/main.sass'
import { AuthProvider } from './src/context/AuthProvider'
import { ApolloProvider } from '@apollo/client'
import { client } from './src/apollo/apolloClient'

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey="6LcZO-EZAAAAALbOOuuRw8JhxR-dzJULF3023I52"
      scriptProps={{
        async: true,
        defer: true,
      }}
    >
      <ApolloProvider client={client}>
        <AuthProvider>
          {element}
        </AuthProvider>
      </ApolloProvider>
    </GoogleReCaptchaProvider>
  )
}
