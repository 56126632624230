import React, { useEffect, useState } from 'react';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { gql, useLazyQuery } from '@apollo/client'

const VIEWER = gql`
	query viewer {
		viewer {
			id
			email
			username
		}
	}
`;

const AuthContext = React.createContext();

export const AuthProvider = (props) => {

  // Query to get user data
  const [getViewer, { data: viewer, loading: isUserLoading }] = useLazyQuery(VIEWER, {
    fetchPolicy: 'network-only',
    onError: () => {
      setIsLoggedIn(false);
      setUserData(null);
    },
  });

  const [isLoggedIn, setIsLoggedIn] = useLocalStorage('isAuth', false);
  const [userData, setUserData] = useState(null);

  // Get user data on login
  useEffect(() => {
    if (isLoggedIn) {
      getViewer();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (viewer) {
      setUserData(viewer.viewer);
    }
  }, [viewer]);

  const context = {
    isLoggedIn,
    setIsLoggedIn,
    isUserLoading,
    userData,
    setUserData,
  };

  return (
    <AuthContext.Provider value={context}>
      {props.children}
    </AuthContext.Provider>
  );

};

export const useAuthContext = () => React.useContext(AuthContext);
