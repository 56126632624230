import fetch from 'cross-fetch'
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  from,
} from '@apollo/client'

const url = process.env.GATSBY_WPGRAPHQL_URL || `https://persson.lebowski.se/graphql`
// const url = 'https://persson.kreativa.dev/graphql'

const httpLink = new HttpLink({
  uri: url,
  credentials: 'include',
  fetch
})

export const client = new ApolloClient({
  link: from([httpLink]),
  cache: new InMemoryCache(),
});
